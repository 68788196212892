import React from "react";
import Shape1 from "../assets/images/shape2.svg";
import Shape2 from "../assets/images/shape2.svg";
import Shape3 from "../assets/images/shape3.svg";
import Shape4 from "../assets/images/shape4.svg";
import ReactHelmet from "../components/ReactHelmet";
import { GoGear } from "react-icons/go";
import { MdMailOutline } from "react-icons/md";
import { IoNotificationsOutline } from "react-icons/io5";
import { CiGrid41 } from "react-icons/ci";
import { LuInfo } from "react-icons/lu";
import { FiMousePointer } from "react-icons/fi";
import { FiHardDrive } from "react-icons/fi";
import { FiSend } from "react-icons/fi";

const FaqsScreen = () => {
  return (
    <React.Fragment>
      <ReactHelmet
        MetaDescription="We make your ideas systematic"
        MetaKeywords="Software development, Full-stack development, Web development agency, Custom software solutions, Technology consulting, Software engineering services, Frontend development, Backend development, Mobile app development, UI/UX design, Agile development, DevOps services, Software architecture, Cloud solutions, Quality assurance (QA) testing, Blockchain Development, Web3 Development"
        canonicalLink="https://www.stackarray.io/faqs/"
        title="Faqs - stackarray.io"
      />
      <div className="page-title-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <h2>FAQs</h2>
            </div>
          </div>
        </div>

        <div className="shape1">
          <img src={require("../assets/images/shape1.png")} alt="shape" />
        </div>
        <div className="shape2 rotateme">
          <img src={Shape2} alt="shape" />
        </div>
        <div className="shape3">
          <img src={Shape3} alt="shape" />
        </div>
        <div className="shape4">
          <img src={Shape4} alt="shape" />
        </div>
        <div className="shape5">
          <img src={require("../assets/images/shape5.png")} alt="shape" />
        </div>
        <div className="shape6 rotateme">
          <img src={Shape4} alt="shape" />
        </div>
        <div className="shape7">
          <img src={Shape4} alt="shape" />
        </div>
        <div className="shape8 rotateme">
          <img src={Shape2} alt="shape" />
        </div>
      </div>

      <div className="container">
        <div className="faq-area ptb-80">
          <div className="container">
            <div className="faq-accordion">
              abcd
            </div>
          </div>
		    </div>
      </div>


      
    </React.Fragment>
  );
};

export default FaqsScreen;
