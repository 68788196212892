import React from "react";
import Shape1 from "../assets/images/shape2.svg";
import Shape2 from "../assets/images/shape2.svg";
import Shape3 from "../assets/images/shape3.svg";
import Shape4 from "../assets/images/shape4.svg";
import ReactHelmet from "../components/ReactHelmet";
import { GoGear } from "react-icons/go";
import { MdMailOutline } from "react-icons/md";
import { IoNotificationsOutline } from "react-icons/io5";
import { CiGrid41 } from "react-icons/ci";
import { LuInfo } from "react-icons/lu";
import { FiMousePointer } from "react-icons/fi";
import { FiHardDrive } from "react-icons/fi";
import { FiSend } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <ReactHelmet
        MetaDescription="We make your ideas systematic"
        MetaKeywords="Software development, Full-stack development, Web development agency, Custom software solutions, Technology consulting, Software engineering services, Frontend development, Backend development, Mobile app development, UI/UX design, Agile development, DevOps services, Software architecture, Cloud solutions, Quality assurance (QA) testing, Blockchain Development, Web3 Development"
        canonicalLink="https://www.stackarray.io/services/"
        title="Page Not Dound - stackarray.io"
      />

      <div className="error-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="error-content">
                <div className="notfound-404">
                  <h1>Oops!</h1>
                </div>
                <h3>404 - Page not found</h3>
                <p>
                  The page you are looking for might have been removed had its
                  name changed or is temporarily unavailable.
                </p>
                <a className="btn btn-primary" onClick={() => navigate("/")}>
                  Go to Homepage
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PageNotFound;
