import React from "react";
import Shape1 from "../assets/images/shape2.svg";
import Shape2 from "../assets/images/shape2.svg";
import Shape3 from "../assets/images/shape3.svg";
import Shape4 from "../assets/images/shape4.svg";
import ReactHelmet from "../components/ReactHelmet";
import { GoGear } from "react-icons/go";
import { MdMailOutline } from "react-icons/md";
import { IoNotificationsOutline } from "react-icons/io5";
import { CiGrid41 } from "react-icons/ci";
import { LuInfo } from "react-icons/lu";
import { FiMousePointer } from "react-icons/fi";
import { FiHardDrive } from "react-icons/fi";
import { FiSend } from "react-icons/fi";

const OurServicesScreen = () => {
  return (
    <React.Fragment>
      <ReactHelmet
        MetaDescription="We make your ideas systematic"
        MetaKeywords="Software development, Full-stack development, Web development agency, Custom software solutions, Technology consulting, Software engineering services, Frontend development, Backend development, Mobile app development, UI/UX design, Agile development, DevOps services, Software architecture, Cloud solutions, Quality assurance (QA) testing, Blockchain Development, Web3 Development"
        canonicalLink="https://www.stackarray.io/services/"
        title="Our Services - stackarray.io"
      />
      <div className="page-title-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <h2>Services</h2>
            </div>
          </div>
        </div>

        <div className="shape1">
          <img src={require("../assets/images/shape1.png")} alt="shape" />
        </div>
        <div className="shape2 rotateme">
          <img src={Shape2} alt="shape" />
        </div>
        <div className="shape3">
          <img src={Shape3} alt="shape" />
        </div>
        <div className="shape4">
          <img src={Shape4} alt="shape" />
        </div>
        <div className="shape5">
          <img src={require("../assets/images/shape5.png")} alt="shape" />
        </div>
        <div className="shape6 rotateme">
          <img src={Shape4} alt="shape" />
        </div>
        <div className="shape7">
          <img src={Shape4} alt="shape" />
        </div>
        <div className="shape8 rotateme">
          <img src={Shape2} alt="shape" />
        </div>
      </div>

      <div className="services-area-two ptb-80 bg-f9f6f6">
        <div className="container">
          <div className="section-title">
            <h2>Our Services</h2>
            <div className="bar"></div>
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p> */}
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6 d-flex">
              <div className="single-services-box">
                <div className="icon">
                  <GoGear size={26} />
                </div>

                <h3>
                  <a href="#">Custom Software Development</a>
                </h3>
                <p>
                  Tailored solutions designed to meet the specific needs and requirements of clients, including web applications, mobile apps, and enterprise software.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 d-flex">
              <div className="single-services-box">
                <div className="icon">
                  <MdMailOutline size={26} />
                </div>

                <h3>
                  <a href="#">Web Development</a>
                </h3>
                <p>
                  Designing and developing websites ranging from simple informational sites to complex e-commerce platforms using technologies such as HTML, CSS, JavaScript, and various frameworks like React, Angular, or Vue.js.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 d-flex">
              <div className="single-services-box">
                <div className="icon">
                  <IoNotificationsOutline size={26} />
                </div>

                <h3>
                  <a href="#">Mobile App Development</a>
                </h3>
                <p>
                  Creating native or cross-platform mobile applications for iOS and Android platforms using tools like Swift, Kotlin, Flutter, or React Native.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 d-flex">
              <div className="single-services-box">
                <div className="icon bg-c679e3">
                  <CiGrid41 size={26} />
                </div>

                <h3>
                  <a href="#">UI/UX Design</a>
                </h3>
                <p>
                  Providing user interface and user experience design services to ensure intuitive, engaging, and visually appealing software interfaces that enhance user satisfaction and usability.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 d-flex">
              <div className="single-services-box">
                <div className="icon bg-c679e3">
                  <LuInfo size={26} />
                </div>

                <h3>
                  <a href="#">Quality Assurance and Testing</a>
                </h3>
                <p>
                  Conducting comprehensive testing processes, including functional testing, performance testing, security testing, and usability testing, to ensure software reliability and quality.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 d-flex">
              <div className="single-services-box">
                <div className="icon bg-c679e3">
                  <FiHardDrive size={26} />
                </div>

                <h3>
                  <a href="#">Cloud Services</a>
                </h3>
                <p>
                  Offering expertise in cloud computing platforms such as Amazon Web Services (AWS), Microsoft Azure, or Google Cloud Platform for scalable, secure, and cost-effective hosting solutions.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 d-flex">
              <div className="single-services-box">
                <div className="icon bg-eb6b3d">
                  <FiMousePointer size={26} />
                </div>

                <h3>
                  <a href="#">Software Maintenance and Support</a>
                </h3>
                <p>
                  Providing ongoing maintenance, updates, and technical support services to ensure the smooth operation and continuous improvement of software applications.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 d-flex">
              <div className="single-services-box">
                <div className="icon bg-eb6b3d">
                  <IoNotificationsOutline size={26} />
                </div>

                <h3>
                  <a href="#">Blockchain Development</a>
                </h3>
                <p>
                  Developing decentralized applications (DApps), smart contracts, and blockchain-based solutions using technologies like Ethereum, Hyperledger, or Corda for secure and transparent transactions.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 d-flex">
              <div className="single-services-box">
                <div className="icon bg-eb6b3d">
                  <FiSend size={26} />
                </div>

                <h3>
                  <a href="#">Data Analytics and Business Intelligence</a>
                </h3>
                <p>
                  Leveraging data analytics tools and techniques to extract valuable insights from data, enabling informed decision-making and optimizing business processes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OurServicesScreen;
