import React from "react";
import Shape1 from "../assets/images/shape2.svg";
import Shape2 from "../assets/images/shape2.svg";
import Shape3 from "../assets/images/shape3.svg";
import Shape4 from "../assets/images/shape4.svg";
import ReactHelmet from "../components/ReactHelmet";

const ServiceDetailPage = ({ data }) => {
  console.log(data);
  return (
    <React.Fragment>
      <ReactHelmet
        MetaDescription={data.description}
        MetaKeywords={data.keywords}
        canonicalLink={`https://www.stackarray.io${data.url}`}
        title={`${data.title} - stackarray.io`}
      />
      <div className="page-title-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <h2>{data.title}</h2>
            </div>
          </div>
        </div>

        <div className="shape1">
          <img src={require("../assets/images/shape1.png")} alt="shape" />
        </div>
        <div className="shape2 rotateme">
          <img src={Shape2} alt="shape" />
        </div>
        <div className="shape3">
          <img src={Shape3} alt="shape" />
        </div>
        <div className="shape4">
          <img src={Shape4} alt="shape" />
        </div>
        <div className="shape5">
          <img src={require("../assets/images/shape5.png")} alt="shape" />
        </div>
        <div className="shape6 rotateme">
          <img src={Shape4} alt="shape" />
        </div>
        <div className="shape7">
          <img src={Shape4} alt="shape" />
        </div>
        <div className="shape8 rotateme">
          <img src={Shape2} alt="shape" />
        </div>
      </div>

      <div className="privacy-policy-area ptb-80">
        <div className="container">
          <div class="privacy-policy-content" dangerouslySetInnerHTML={{__html: data.content}}>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ServiceDetailPage;
