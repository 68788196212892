import React from "react";
import Shape1 from "../assets/images/shape2.svg";
import Shape2 from "../assets/images/shape2.svg";
import Shape3 from "../assets/images/shape3.svg";
import Shape4 from "../assets/images/shape4.svg";
import Blogs1 from "../assets/images/blog-image/1.jpg";
import Blogs2 from "../assets/images/blog-image/2.jpg";
import Blogs3 from "../assets/images/blog-image/3.jpg";
import Blogs4 from "../assets/images/blog-image/4.jpg";
import ReactHelmet from "../components/ReactHelmet";
import { GoGear } from "react-icons/go";
import { MdMailOutline } from "react-icons/md";
import { IoNotificationsOutline } from "react-icons/io5";
import { CiGrid41 } from "react-icons/ci";
import { LuInfo } from "react-icons/lu";
import { FiMousePointer } from "react-icons/fi";
import { FiHardDrive } from "react-icons/fi";
import { FiSend } from "react-icons/fi";

const BlogsScreen = () => {
  return (
    <React.Fragment>
      <ReactHelmet
        MetaDescription="We make your ideas systematic"
        MetaKeywords="Software development, Full-stack development, Web development agency, Custom software solutions, Technology consulting, Software engineering services, Frontend development, Backend development, Mobile app development, UI/UX design, Agile development, DevOps services, Software architecture, Cloud solutions, Quality assurance (QA) testing, Blockchain Development, Web3 Development"
        canonicalLink="https://www.stackarray.io/blogs/"
        title="Blogs - stackarray.io"
      />
      <div className="page-title-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <h2>Blogs</h2>
            </div>
          </div>
        </div>

        <div className="shape1">
          <img src={require("../assets/images/shape1.png")} alt="shape" />
        </div>
        <div className="shape2 rotateme">
          <img src={Shape2} alt="shape" />
        </div>
        <div className="shape3">
          <img src={Shape3} alt="shape" />
        </div>
        <div className="shape4">
          <img src={Shape4} alt="shape" />
        </div>
        <div className="shape5">
          <img src={require("../assets/images/shape5.png")} alt="shape" />
        </div>
        <div className="shape6 rotateme">
          <img src={Shape4} alt="shape" />
        </div>
        <div className="shape7">
          <img src={Shape4} alt="shape" />
        </div>
        <div className="shape8 rotateme">
          <img src={Shape2} alt="shape" />
        </div>
      </div>

		<div className="blog-area ptb-80">
			<div className="container">
				<div className="row">
					<div className="col-lg-4 col-md-6">
                        <div className="single-blog-post-box">
                            <div className="entry-thumbnail">
                                <a href="#"><img src={Blogs1} alt="image" /></a>
                            </div>

                            <div className="entry-post-content">
                                <div className="entry-meta">
                                    <ul>
                                        <li><a href="blog-1.html">Admin</a></li>
                                        <li>August 15, 2019</li>
                                    </ul>
                                </div>

                                <h3><a href="single-blog.html">Making Peace With The Feast Or Famine Of Freelancing</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                                <a href="single-blog.html" className="learn-more-btn">
									Read Story 
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
								</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post-box">
                            <div className="entry-thumbnail">
                                <a href="#"><img src={Blogs2} alt="image" /></a>
                            </div>

                            <div className="entry-post-content">
                                <div className="entry-meta">
                                    <ul>
                                        <li><a href="blog-1.html">Admin</a></li>
                                        <li>August 18, 2019</li>
                                    </ul>
                                </div>

                                <h3><a href="single-blog.html">I Used The Web For A Day On A 50 MB Budget</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                                <a href="single-blog.html" className="learn-more-btn">
									Read Story 
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
								</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post-box">
                            <div className="entry-thumbnail">
                                <a href="#"><img src={Blogs3} alt="image" /></a>
                            </div>

                            <div className="entry-post-content">
                                <div className="entry-meta">
                                    <ul>
                                        <li><a href="blog-1.html">Admin</a></li>
                                        <li>August 15, 2019</li>
                                    </ul>
                                </div>

                                <h3><a href="single-blog.html">Here are the 5 most telling signs of micromanagement</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                                <a href="single-blog.html" className="learn-more-btn">
									Read Story
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
								</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post-box">
                            <div className="entry-thumbnail">
                                <a href="#"><img src={Blogs4} alt="image" /></a>
                            </div>

                            <div className="entry-post-content">
                                <div className="entry-meta">
                                    <ul>
                                        <li><a href="blog-1.html">Admin</a></li>
                                        <li>August 15, 2019</li>
                                    </ul>
                                </div>

                                <h3><a href="single-blog.html">The security risks of changing package owners</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                                <a href="single-blog.html" className="learn-more-btn">
									Read Story 
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
								</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post-box">
                            <div className="entry-thumbnail">
                                <a href="#"><img src={Blogs1} alt="image" /></a>
                            </div>

                            <div className="entry-post-content">
                                <div className="entry-meta">
                                    <ul>
                                        <li><a href="blog-1.html">Admin</a></li>
                                        <li>August 18, 2019</li>
                                    </ul>
                                </div>

                                <h3><a href="single-blog.html">Tips to Protecting Your Business and Family</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                                <a href="single-blog.html" className="learn-more-btn"
									>Read Story 
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
								</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post-box">
                            <div className="entry-thumbnail">
                                <a href="#"><img src={Blogs2} alt="image" /></a>
                            </div>

                            <div className="entry-post-content">
                                <div className="entry-meta">
                                    <ul>
                                        <li><a href="blog-1.html">Admin</a></li>
                                        <li>August 15, 2019</li>
                                    </ul>
                                </div>

                                <h3><a href="single-blog.html">Protect Your Workplace from Cyber Attacks</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                                <a href="single-blog.html" className="learn-more-btn">
									Read Story 
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
								</a>
                            </div>
                        </div>
                    </div>
					
					<div className="col-lg-12 col-md-12">
						<div className="pagination-area">
							<nav aria-label="Page navigation">
								<ul className="pagination justify-content-center">
								   
									<li className="page-item"><a className="page-link" href="#">Prev</a></li>
									
									<li className="page-item active"><a className="page-link" href="#">1</a></li>
									
									<li className="page-item"><a className="page-link" href="#">2</a></li>
									
									<li className="page-item"><a className="page-link" href="#">3</a></li>
									
									<li className="page-item"><a className="page-link" href="#">Next</a></li>
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</div>
      
    </React.Fragment>
  );
};

export default BlogsScreen;
