import React, { useState } from "react";
import Shape1 from "../assets/images/shape2.svg";
import Shape2 from "../assets/images/shape2.svg";
import Shape3 from "../assets/images/shape3.svg";
import Shape4 from "../assets/images/shape4.svg";
import Vector from "../assets/images/contactus-vector.png";
import ReactHelmet from "../components/ReactHelmet";
import { IoLocationOutline } from "react-icons/io5";
import { TfiEmail } from "react-icons/tfi";
import { FiPhoneCall } from "react-icons/fi";
import { toast } from 'react-toastify';

const ContactUsScreen = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [service, setService] = useState('');
  const [comment, setComment] = useState('');
  const [companyName, setCompanyName] = useState('');

  const validateEmail = (email) => {
    try {
      const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (reg.test(email) === true) {
        return true;
      }
      else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || name === '') {
      toast.error('Name is required!', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
    }
    else if (!email || email === '') {
      toast.error('Email is required!', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
    }
    else if (!validateEmail(email.trim())) {
      toast.error('Invalid Email Address!', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
    }
    else if (!number || number === '') {
      toast.error('Phone number is required!', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
    }
    else if (!service || service === '') {
      toast.error('Service is required!', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
    }
    else if (!companyName || companyName === '') {
      toast.error('Company aame is required!', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
    }
    else {
      const promise = new Promise(async (resolve, reject) => {

        try {
          const response = await fetch('https://script.google.com/macros/s/AKfycbwXEs0c361i3K7khEcINVCWIYilO5nLvExgDfXLOuDEHgkertz0hJhftz2TR6kL4CAm_g/exec', {
            redirect: "follow",
            method: 'POST',
            headers: {
              "Content-Type": "text/plain;charset=utf-8",
            },
            body: JSON.stringify({
              name: name,
              email: email.trim(),
              number: number,
              service: service,
              comment: comment,
              companyName: companyName
            })
          });
          if (response.ok) {
            setName('');
            setEmail('');
            setNumber('');
            setService('');
            setComment('');
            setCompanyName('');
            resolve();
          } else {
            reject();
          }
        } catch (error) {
          reject();
        }

      });

      toast.promise(
        promise,
        {
          pending: 'Thank you for your patience as we process your query ⌛',
          success: 'Query successfully submitted!👍',
          error: 'Something went wroung 🙁'
        }
      )
    }



  };

  return (
    <React.Fragment>
      <ReactHelmet
        MetaDescription="We make your ideas systematic"
        MetaKeywords="Software development, Full-stack development, Web development agency, Custom software solutions, Technology consulting, Software engineering services, Frontend development, Backend development, Mobile app development, UI/UX design, Agile development, DevOps services, Software architecture, Cloud solutions, Quality assurance (QA) testing, Blockchain Development, Web3 Development"
        canonicalLink="https://www.stackarray.io/contact-us/"
        title="Contact Us - stackarray.io"
      />
      <div className="page-title-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <h2>Contact Us</h2>
            </div>
          </div>
        </div>

        <div className="shape1">
          <img src={require("../assets/images/shape1.png")} alt="shape" />
        </div>
        <div className="shape2 rotateme">
          <img src={Shape2} alt="shape" />
        </div>
        <div className="shape3">
          <img src={Shape3} alt="shape" />
        </div>
        <div className="shape4">
          <img src={Shape4} alt="shape" />
        </div>
        <div className="shape5">
          <img src={require("../assets/images/shape5.png")} alt="shape" />
        </div>
        <div className="shape6 rotateme">
          <img src={Shape4} alt="shape" />
        </div>
        <div className="shape7">
          <img src={Shape4} alt="shape" />
        </div>
        <div className="shape8 rotateme">
          <img src={Shape2} alt="shape" />
        </div>
      </div>
      <div className="contact-info-area ptb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="contact-info-box">
                <div className="icon">
                  <TfiEmail size={24} />
                </div>
                <h3>Mail Here</h3>
                <p>
                  <a href="#">
                    <span className="__cf_email__"></span> support@stackarray.io
                  </a>
                </p>
                {/* <p>
                  <a href="#">
                    <span className="__cf_email__"></span>abc@gmail.com
                  </a>
                </p> */}
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="contact-info-box">
                <div className="icon">
                  <IoLocationOutline size={28} />
                </div>
                <h3>Visit Here</h3>
                <p>
                  5900 Balcones Dr #14880, Austin, TX 78731
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
              <div className="contact-info-box">
                <div className="icon">
                  <FiPhoneCall size={24} />
                </div>
                <h3>Call Here</h3>
                <p>
                  <a href="#">+17373075660</a>
                </p>
                {/* <p>
                  <a href="#">+241 452 4526</a>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3443.3302279788536!2d-97.75752952381725!3d30.34156350443115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8644cae2feb064b3%3A0xfbcc19243aa6ddc0!2s5900%20Balcones%20Dr%2C%20Austin%2C%20TX%2078731%2C%20USA!5e0!3m2!1sen!2s!4v1711534878355!5m2!1sen!2s"></iframe>
      </div>

      <div className="contact-area ptb-80">
        <div className="container">
          <div className="section-title">
            <h2>Get In Touch With Us</h2>
            <div className="bar"></div>
            <p>Anything On your Mind. We’ll Be Glad To Assist You!</p>
          </div>

          <div className="row h-100 justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12">
              <img src={Vector} alt="image" />
            </div>

            <div className="col-lg-6 col-md-12">
              <form id="contactForm">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="form-control"
                        data-error="Please enter your name"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="company"
                        id="company"
                        className="form-control"
                        data-error="Please enter your subject"
                        placeholder="Company Name"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control"
                        data-error="Please enter your email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="phone_number"
                        id="phone_number"
                        data-error="Please enter your number"
                        className="form-control"
                        placeholder="Phone"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="msg_subject"
                        id="msg_subject"
                        className="form-control"
                        data-error="Please enter your subject"
                        placeholder="Service"
                        value={service}
                        onChange={(e) => setService(e.target.value)}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        className="form-control"
                        id="message"
                        cols="30"
                        rows="5"
                        data-error="Write your message"
                        placeholder="Your Message"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      ></textarea>
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <button type="button" onClick={handleSubmit} className="btn btn-primary">
                      Send Message
                    </button>
                    <div id="msgSubmit" className="h3 text-center hidden"></div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContactUsScreen;
