import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

const LoadingScreen = () => {

  const loader = useSelector((state) => state.CommonReducer.loader);
  const [loading, setLoading] = useState(loader);

  useEffect(() => {

    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setLoading(loader);
  }, [loader]);

  return (
    <React.Fragment>
      {loading ? (
        <div className="preloader">
          <div className="spinner">
            <img
              src={require("../assets/images/stackarray-icon-t.png")}
              data-wow-delay="0.6s"
              alt="stackarray"
            />
          </div>
        </div>
      ) : null
      }
    </React.Fragment>);
};

export default LoadingScreen;
