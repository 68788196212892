import Slider from "react-slick";

const OurTeams = ({ settings }) => {
  return (
    <div className="container-fluid p-0 teams-slider">
      <Slider {...settings} className="team-slides owl-carousel owl-theme">
        <div className="single-team">
          <div className="team-image">
            <img
              src={require("../assets/images/team-image/1.jpg")}
              alt="image"
            />
          </div>

          <div className="team-content">
            <div className="team-info">
              <h3>Josh Buttler</h3>
              <span>CEO & Founder</span>
            </div>

            <ul>
              <li>
                <a href="#" target="_blank">
                  <i data-feather="facebook"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i data-feather="twitter"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i data-feather="linkedin"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i data-feather="gitlab"></i>
                </a>
              </li>
            </ul>

            <p>
              Risus commodo viverra maecenas accumsan lacus vel facilisis quis
              ipsum.{" "}
            </p>
          </div>
        </div>

        <div className="single-team">
          <div className="team-image">
            <img
              src={require("../assets/images/team-image/2.jpg")}
              alt="image"
            />
          </div>

          <div className="team-content">
            <div className="team-info">
              <h3>Alex Maxwel</h3>
              <span>Marketing Manager</span>
            </div>

            <ul>
              <li>
                <a href="#" target="_blank">
                  <i data-feather="facebook"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i data-feather="twitter"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i data-feather="linkedin"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i data-feather="gitlab"></i>
                </a>
              </li>
            </ul>

            <p>
              Risus commodo viverra maecenas accumsan lacus vel facilisis quis
              ipsum.{" "}
            </p>
          </div>
        </div>

        <div className="single-team">
          <div className="team-image">
            <img
              src={require("../assets/images/team-image/3.jpg")}
              alt="image"
            />
          </div>

          <div className="team-content">
            <div className="team-info">
              <h3>Janny Cotller</h3>
              <span>Web Developer</span>
            </div>

            <ul>
              <li>
                <a href="#" target="_blank">
                  <i data-feather="facebook"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i data-feather="twitter"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i data-feather="linkedin"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i data-feather="gitlab"></i>
                </a>
              </li>
            </ul>

            <p>
              Risus commodo viverra maecenas accumsan lacus vel facilisis quis
              ipsum.{" "}
            </p>
          </div>
        </div>

        <div className="single-team">
          <div className="team-image">
            <img
              src={require("../assets/images/team-image/4.jpg")}
              alt="image"
            />
          </div>

          <div className="team-content">
            <div className="team-info">
              <h3>Jason Statham</h3>
              <span>UX/UI Designer</span>
            </div>

            <ul>
              <li>
                <a href="#" target="_blank">
                  <i data-feather="facebook"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i data-feather="twitter"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i data-feather="linkedin"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i data-feather="gitlab"></i>
                </a>
              </li>
            </ul>

            <p>
              Risus commodo viverra maecenas accumsan lacus vel facilisis quis
              ipsum.{" "}
            </p>
          </div>
        </div>

        <div className="single-team">
          <div className="team-image">
            <img
              src={require("../assets/images/team-image/5.jpg")}
              alt="image"
            />
          </div>

          <div className="team-content">
            <div className="team-info">
              <h3>Corey Anderson</h3>
              <span>Project Manager</span>
            </div>

            <ul>
              <li>
                <a href="#" target="_blank">
                  <i data-feather="facebook"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i data-feather="twitter"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i data-feather="linkedin"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i data-feather="gitlab"></i>
                </a>
              </li>
            </ul>

            <p>
              Risus commodo viverra maecenas accumsan lacus vel facilisis quis
              ipsum.{" "}
            </p>
          </div>
        </div>

        <div className="single-team">
          <div className="team-image">
            <img
              src={require("../assets/images/team-image/1.jpg")}
              alt="image"
            />
          </div>

          <div className="team-content">
            <div className="team-info">
              <h3>Josh Buttler</h3>
              <span>CEO & Founder</span>
            </div>

            <ul>
              <li>
                <a href="#" target="_blank">
                  <i data-feather="facebook"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i data-feather="twitter"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i data-feather="linkedin"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i data-feather="gitlab"></i>
                </a>
              </li>
            </ul>

            <p>
              Risus commodo viverra maecenas accumsan lacus vel facilisis quis
              ipsum.{" "}
            </p>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default OurTeams;
