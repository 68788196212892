import React from "react";
import { useNavigate } from "react-router-dom";
import Shape2 from "../assets/images/shape2.svg";
import Shape3 from "../assets/images/shape3.svg";
import Shape4 from "../assets/images/shape4.svg";
import Vector from "../assets/images/contactus-vector.png";
import ReactHelmet from "../components/ReactHelmet";
import OurTeams from "../components/our-teams";

const AboutUsScreen = () => {
  const navigate = useNavigate();

  const customSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1260,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <ReactHelmet
        MetaDescription="We make your ideas systematic"
        MetaKeywords="Software development, Full-stack development, Web development agency, Custom software solutions, Technology consulting, Software engineering services, Frontend development, Backend development, Mobile app development, UI/UX design, Agile development, DevOps services, Software architecture, Cloud solutions, Quality assurance (QA) testing, Blockchain Development, Web3 Development"
        canonicalLink="https://www.stackarray.io/about-us/"
        title="About Us - stackarray.io"
      />
      <div className="page-title-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <h2>About Us</h2>
            </div>
          </div>
        </div>

        <div className="shape1">
          <img src={require("../assets/images/shape1.png")} alt="shape" />
        </div>
        <div className="shape2 rotateme">
          <img src={Shape2} alt="shape" />
        </div>
        <div className="shape3">
          <img src={Shape3} alt="shape" />
        </div>
        <div className="shape4">
          <img src={Shape4} alt="shape" />
        </div>
        <div className="shape5">
          <img src={require("../assets/images/shape5.png")} alt="shape" />
        </div>
        <div className="shape6 rotateme">
          <img src={Shape4} alt="shape" />
        </div>
        <div className="shape7">
          <img src={Shape4} alt="shape" />
        </div>
        <div className="shape8 rotateme">
          <img src={Shape2} alt="shape" />
        </div>
      </div>

      <div className="about-area ptb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="about-image">
                <img src={Vector} alt="image" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="about-content text-start">
                <div className="section-title">
                  <h2>About Us</h2>
                  <div className="bar"></div>
                  <p>
                    At Stackarray.io, we are at the forefront of catalyzing tech transformation, partnering with businesses to navigate the digital landscape with precision and innovation. Specializing in intuitive iOS and Android app development, and robust enterprise solutions, we empower your business to revolutionize processes and positively impact lives. Our expertise lies in harnessing the power of software development and data analytics to deliver transformative technology solutions that elevate user experiences and drive success.
                  </p>
                  <br />
                  <p>
                    Furthermore, we don't just build custom solutions,  we also boast a library of in-house, customizable products ready for private labeling. This allows you to leverage pre-built functionalities tailored to your specific needs and brand identity, saving you valuable time and resources on your development journey.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="about-inner-area">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="about-text">
                  <h3>Our History</h3>
                  <p>
                    Founded in 2017 on the principles of innovation, integrity, and impact, Stackarray.io began as a vision to bridge the gap between technological potential and practical, impactful solutions.Our journey has been marked by relentless determination, continuous evolution, and a steadfast commitment to our clients and their success.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="about-text">
                  <h3>Our Mission</h3>
                  <p>
                    Our mission is to empower businesses and individuals with cutting-edge technology solutions that streamline processes, enhance productivity, and create meaningful impacts. We believe in the transformative power of technology to change lives, communities, and industries for the better.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div className="about-text">
                  <h3>Who we are</h3>
                  <p>
                    We are creators, innovators, and problem-solvers. At Stackarray.io, we unite a diverse team of skilled professionals, each bringing unique perspectives and expertise to our collective mission. Our culture is built on relentless determination, continuous evolution, unity in adversity, and a stakeholder-centric approach to solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="team-area ptb-80 bg-f9f6f6 d-none">
        <div className="container">
          <div className="section-title">
            <h2>Our Awesome Team</h2>
            <div className="bar"></div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
        </div>

        <OurTeams settings={customSettings} />
      </div>
    </React.Fragment>
  );
};

export default AboutUsScreen;
