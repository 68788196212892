import React from "react";
import Shape1 from "../assets/images/shape2.svg";
import Shape2 from "../assets/images/shape2.svg";
import Shape3 from "../assets/images/shape3.svg";
import Shape4 from "../assets/images/shape4.svg";
import Blogs1 from "../assets/images/blog-image/1.jpg";
import Blogs2 from "../assets/images/blog-image/2.jpg";
import Blogs3 from "../assets/images/blog-image/3.jpg";
import Blogs4 from "../assets/images/blog-image/4.jpg";
import { IoSearchOutline } from "react-icons/io5";
import ReactHelmet from "../components/ReactHelmet";

const ProjectScreen = () => {
  return (
    <React.Fragment>
      <ReactHelmet
        MetaDescription="We make your ideas systematic"
        MetaKeywords="Software development, Full-stack development, Web development agency, Custom software solutions, Technology consulting, Software engineering services, Frontend development, Backend development, Mobile app development, UI/UX design, Agile development, DevOps services, Software architecture, Cloud solutions, Quality assurance (QA) testing, Blockchain Development, Web3 Development"
        canonicalLink="https://www.stackarray.io/faqs/"
        title="Projects - stackarray.io"
      />
      <div className="page-title-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <h2>Projects</h2>
            </div>
          </div>
        </div>

        <div className="shape1">
          <img src={require("../assets/images/shape1.png")} alt="shape" />
        </div>
        <div className="shape2 rotateme">
          <img src={Shape2} alt="shape" />
        </div>
        <div className="shape3">
          <img src={Shape3} alt="shape" />
        </div>
        <div className="shape4">
          <img src={Shape4} alt="shape" />
        </div>
        <div className="shape5">
          <img src={require("../assets/images/shape5.png")} alt="shape" />
        </div>
        <div className="shape6 rotateme">
          <img src={Shape4} alt="shape" />
        </div>
        <div className="shape7">
          <img src={Shape4} alt="shape" />
        </div>
        <div className="shape8 rotateme">
          <img src={Shape2} alt="shape" />
        </div>
      </div>

      

      <div className="blog-area ptb-80">
			<div className="container">
				<div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="single-blog-post">
                                    <div className="blog-image">
                                        <a href="#">
                                            <img src={Blogs1} alt="image" />
                                        </a>
        
                                        <div className="date">
                                            <i data-feather="calendar"></i> March 15, 2019
                                        </div>
                                    </div>
        
                                    <div className="blog-post-content">
                                        <h3><a href="single-blog.html">The security risks of changing package owners</a></h3>
        
                                        <span>by <a href="#">admin</a></span>
        
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
        
                                        <a href="#" className="read-more-btn">Read More <i data-feather="arrow-right"></i> </a>
                                    </div>
                                </div>
                            </div>
        
                            <div className="col-lg-6 col-md-6">
                                <div className="single-blog-post">
                                    <div className="blog-image">
                                        <a href="#">
                                            <img src={Blogs2} alt="image" />
                                        </a>
        
                                        <div className="date">
                                            <i data-feather="calendar"></i> March 17, 2019
                                        </div>
                                    </div>
        
                                    <div className="blog-post-content">
                                        <h3><a href="single-blog.html">Tips to Protecting Your Business and Family</a></h3>
        
                                        <span>by <a href="#">smith</a></span>
        
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
        
                                        <a href="#" className="read-more-btn">Read More <i data-feather="arrow-right"></i> </a>
                                    </div>
                                </div>
                            </div>
        
                            <div className="col-lg-6 col-md-6">
                                <div className="single-blog-post">
                                    <div className="blog-image">
                                        <a href="#">
                                            <img src={Blogs3} alt="image" />
                                        </a>
        
                                        <div className="date">
                                            <i data-feather="calendar"></i> March 19, 2019
                                        </div>
                                    </div>
        
                                    <div className="blog-post-content">
                                        <h3><a href="single-blog.html">Protect Your Workplace from Cyber Attacks</a></h3>
        
                                        <span>by <a href="#">john</a></span>
        
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
        
                                        <a href="#" className="read-more-btn">Read More <i data-feather="arrow-right"></i> </a>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-6 col-md-6">
                                <div className="single-blog-post">
                                    <div className="blog-image">
                                        <a href="#">
                                            <img src={Blogs4} alt="image" />
                                        </a>
        
                                        <div className="date">
                                            <i data-feather="calendar"></i> March 15, 2019
                                        </div>
                                    </div>
        
                                    <div className="blog-post-content">
                                        <h3><a href="single-blog.html">The security risks of changing package owners</a></h3>
        
                                        <span>by <a href="#">admin</a></span>
        
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
        
                                        <a href="#" className="read-more-btn">Read More <i data-feather="arrow-right"></i> </a>
                                    </div>
                                </div>
                            </div>
        
                            <div className="col-lg-6 col-md-6">
                                <div className="single-blog-post">
                                    <div className="blog-image">
                                        <a href="#">
                                            <img src={Blogs1} alt="image" />
                                        </a>
        
                                        <div className="date">
                                            <i data-feather="calendar"></i> March 17, 2019
                                        </div>
                                    </div>
        
                                    <div className="blog-post-content">
                                        <h3><a href="single-blog.html">Tips to Protecting Your Business and Family</a></h3>
        
                                        <span>by <a href="#">smith</a></span>
        
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
        
                                        <a href="#" className="read-more-btn">Read More <i data-feather="arrow-right"></i> </a>
                                    </div>
                                </div>
                            </div>
        
                            <div className="col-lg-6 col-md-6">
                                <div className="single-blog-post">
                                    <div className="blog-image">
                                        <a href="#">
                                            <img src={Blogs2} alt="image" />
                                        </a>
        
                                        <div className="date">
                                            <i data-feather="calendar"></i> March 19, 2019
                                        </div>
                                    </div>
        
                                    <div className="blog-post-content">
                                        <h3><a href="single-blog.html">Protect Your Workplace from Cyber Attacks</a></h3>
        
                                        <span>by <a href="#">john</a></span>
        
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
        
                                        <a href="#" className="read-more-btn">Read More <i data-feather="arrow-right"></i> </a>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-12 col-md-12">
                                <div className="pagination-area">
                                    <nav aria-label="Page navigation">
                                        <ul className="pagination justify-content-center">
                                           
                                            <li className="page-item"><a className="page-link" href="#">Prev</a></li>
                                            
                                            <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                            
                                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                                            
                                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                                            
                                            <li className="page-item"><a className="page-link" href="#">Next</a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <aside className="widget-area" id="secondary">
                            <div className="widget widget_search">
                                <form className="search-form">
                                    <label>
                                        <span className="screen-reader-text">Search for:</span>
                                        <input type="search" className="search-field" placeholder="Search..." />
                                    </label>
                                    <button type="submit"><IoSearchOutline /></button>
                                </form>
                            </div>

                            <div className="widget widget_startp_posts_thumb">
                                <h3 className="widget-title">Popular Posts</h3>

                                <article className="item">
                                    <a href="#" className="thumb">
                                        <span className="fullimage cover bg1" role="img"></span>
                                    </a>
                                    <div className="info">
                                        <time dateTime="2019-06-30">June 10, 2019</time>
                                        <h4 className="title usmall"><a href="#">Making Peace With The Feast Or Famine Of Freelancing</a></h4>
                                    </div>

                                    <div className="clear"></div>
                                </article>

                                <article className="item">
                                    <a href="#" className="thumb">
                                        <span className="fullimage cover bg2" role="img"></span>
                                    </a>
                                    <div className="info">
                                        <time dateTime="2019-06-30">June 21, 2019</time>
                                        <h4 className="title usmall"><a href="#">I Used The Web For A Day On A 50 MB Budget</a></h4>
                                    </div>

                                    <div className="clear"></div>
                                </article>

                                <article className="item">
                                    <a href="#" className="thumb">
                                        <span className="fullimage cover bg3" role="img"></span>
                                    </a>
                                    <div className="info">
                                        <time dateTime="2019-06-30">June 30, 2019</time>
                                        <h4 className="title usmall"><a href="#">How To Create A Responsive Popup Gallery?</a></h4>
                                    </div>

                                    <div className="clear"></div>
                                </article>
                            </div>

                            <div className="widget widget_categories">
                                <h3 className="widget-title">Categories</h3>

                                <ul>
                                    <li><a href="#">Business</a></li>
                                    <li><a href="#">Privacy</a></li>
                                    <li><a href="#">Technology</a></li>
                                    <li><a href="#">Tips</a></li>
                                    <li><a href="#">Uncategorized</a></li>
                                </ul>
                            </div>

                            <div className="widget widget_archive">
                                <h3 className="widget-title">Archives</h3>

                                <ul>
                                    <li><a href="#">May 2019</a></li>
                                    <li><a href="#">April 2019</a></li>
                                    <li><a href="#">June 2019</a></li>
                                </ul>
                            </div>

                            <div className="widget widget_meta">
                                <h3 className="widget-title">Meta</h3>

                                <ul>
                                    <li><a href="#">Log in</a></li>
                                    <li><a href="#">Entries <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                                    <li><a href="#">Comments <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                                    <li><a href="#">WordPress.org</a></li>
                                </ul>
                            </div>

                            <div className="widget widget_tag_cloud">
                                <h3 className="widget-title">Tags</h3>

                                <div className="tagcloud">
                                    <a href="#">IT <span className="tag-link-count"> (3)</span></a>
                                    <a href="#">Spacle <span className="tag-link-count"> (3)</span></a>
                                    <a href="#">Games <span className="tag-link-count"> (2)</span></a>
                                    <a href="#">Fashion <span className="tag-link-count"> (2)</span></a>
                                    <a href="#">Travel <span className="tag-link-count"> (1)</span></a>
                                    <a href="#">Smart <span className="tag-link-count"> (1)</span></a>
                                    <a href="#">Marketing <span className="tag-link-count"> (1)</span></a>
                                    <a href="#">Tips <span className="tag-link-count"> (2)</span></a>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
			</div>
		</div>
      
    </React.Fragment>
  );
};

export default ProjectScreen;
