import React from "react";
import Shape1 from "../assets/images/shape2.svg";
import Shape2 from "../assets/images/shape2.svg";
import Shape3 from "../assets/images/shape3.svg";
import Shape4 from "../assets/images/shape4.svg";
import ReactHelmet from "../components/ReactHelmet";

const PrivacyPolicyScreen = () => {
  return (
    <React.Fragment>
      <ReactHelmet
        MetaDescription="We make your ideas systematic"
        MetaKeywords="Software development, Full-stack development, Web development agency, Custom software solutions, Technology consulting, Software engineering services, Frontend development, Backend development, Mobile app development, UI/UX design, Agile development, DevOps services, Software architecture, Cloud solutions, Quality assurance (QA) testing, Blockchain Development, Web3 Development"
        canonicalLink="https://www.stackarray.io/faqs/"
        title="Privacy Policy - stackarray.io"
      />
      <div className="page-title-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <h2>Privacy Policy</h2>
            </div>
          </div>
        </div>

        <div className="shape1">
          <img src={require("../assets/images/shape1.png")} alt="shape" />
        </div>
        <div className="shape2 rotateme">
          <img src={Shape2} alt="shape" />
        </div>
        <div className="shape3">
          <img src={Shape3} alt="shape" />
        </div>
        <div className="shape4">
          <img src={Shape4} alt="shape" />
        </div>
        <div className="shape5">
          <img src={require("../assets/images/shape5.png")} alt="shape" />
        </div>
        <div className="shape6 rotateme">
          <img src={Shape4} alt="shape" />
        </div>
        <div className="shape7">
          <img src={Shape4} alt="shape" />
        </div>
        <div className="shape8 rotateme">
          <img src={Shape2} alt="shape" />
        </div>
      </div>

      <div className="privacy-policy-area ptb-80">
        <div className="container">
          <div class="privacy-policy-content">
            <h4>Intellectual property indemnity</h4>
            <p>We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, subscribe to the newsletter, fill out a form, and in connection with other activities, services, features or resources we make <a href="#">available</a> on our Site. Users may be asked for, as appropriate, name, email address, mailing address, phone number, company name. We will collect personal <strong>identification</strong> information from Users only if they voluntarily consent such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site related activities.</p>
            <h4>General permission to use and access and use limitations</h4>
            <p>This site is provided by <strong>StartP</strong>. <strong>StartP</strong> collects information in several ways from different parts of this site.</p>
            <p><strong>1.</strong> Complimentary ground shipping within 1 to 7 business days<br />
              <strong>2.</strong> In-store collection available within 1 to 7 business days<br />
              <strong>3.</strong> Next-day and Express delivery options also available<br />
              <strong>4.</strong> Purchases are delivered in an orange box tied with a Bolduc ribbon, with the exception of certain items<br />
              <strong>5.</strong> See the delivery FAQs for details on shipping methods, costs and delivery times
            </p>
            <h4>Limitation of liability</h4>
            <p><strong>StartP</strong> accepts the following payment methods:</p>
            <ul>
              <li>Credit Card: <strong>Visa</strong>, <strong>MasterCard</strong>, <strong>Discover</strong>, <strong>American Express</strong>, <strong>JCB</strong>, <strong>Visa Electron</strong>. The total will be charged to your card when the order is shipped.</li>
              <li><strong>StartP</strong> features a Fast Checkout option, allowing you to securely save your credit card details so that you don't have to re-enter them for future purchases.</li>
              <li>PayPal: Shop easily online without having to enter your credit card details on the website.Your account will be charged once the order is completed. To register for a PayPal account, visit the website <a href="#" target="_blank">paypal.com.</a></li>
            </ul>
            <h4>Linking to this Site</h4>
            <p>Items returned within 14 days of their original shipment date in same as new condition will be eligible for a full refund or store credit. Refunds will be charged back form of payment used for purchase. Customer is responsible for shipping charges when making returns and shipping/handling fees of original purchase is non-refundable.</p>
            <h4>Intellectual property</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget euismod erat, sit amet vulputate enim. Etiam enim tellus, maximus vel augue sed, pharetra hendrerit orci. Vivamus sed massa in nibh imperdiet mattis quis sed augue. Pellentesque erat metus, vestibulum nec nisl.</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget euismod erat, sit amet vulputate enim. Etiam enim tellus.</p>
            <h4>Security and storage</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget euismod erat, sit amet vulputate enim. Etiam enim tellus.</p>
            <ol>
              <li>Credit Card: <strong>Visa</strong>, <strong>MasterCard</strong>, <strong>Discover</strong>, <strong>American Express</strong>, <strong>JCB</strong>, <strong>Visa Electron</strong>. The total will be charged to your card when the order is shipped.</li>
              <li><strong>StartP</strong> features a Fast Checkout option, allowing you to securely save your credit card details so that you don't have to re-enter them for future purchases.</li>
              <li>PayPal: Shop easily online without having to enter your credit card details on the website.Your account will be charged once the order is completed. To register for a PayPal account, visit the website <a href="#" target="_blank">paypal.com.</a></li>
            </ol>
            <p>Last updated: June 30, 2021 at 24:00 pm</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PrivacyPolicyScreen;
