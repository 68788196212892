import React, { useEffect } from "react";
import { Route, Routes, BrowserRouter as Router, useLocation } from "react-router-dom";
import HomeScreen from "./screens/Home";
import ContactUsScreen from "./screens/Contactus";
import Header from "./components/header";
import Footer from "./components/footer";
import LoadingScreen from "./screens/LoadingScreen";
import "./App.css";
import AboutUsScreen from "./screens/Aboutus";
import OurServicesScreen from "./screens/OurServices";
import FaqsScreen from "./screens/Faqs";
import PageNotFound from "./screens/PageNotFound";
import BlogsScreen from "./screens/Blogs";
import PrivacyPolicyScreen from "./screens/PrivacyPolicy";
import ServiceDetailPage from "./screens/ServiceDetailPage";
import TermsAndConditionScreen from "./screens/TermsAndCondition";
import ProjectScreen from "./screens/Projects";
import { Provider } from 'react-redux';
import { store } from './store/Store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import data from './assets/json/data.json';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  
  return (
    <Provider store={store}>
      <Router>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div className="main-layout">
          <Header />
          <div className="inner-layout">
            <LoadingScreen />
            <ScrollToTop />
            <Routes>
              <Route path="*" element={<PageNotFound />} />
              <Route path="/" element={<HomeScreen />} />
              <Route path="/contact-us" element={<ContactUsScreen />} />
              <Route path="/about-us" element={<AboutUsScreen />} />
              <Route path="/services" element={<OurServicesScreen />} />
              <Route path="/faqs" element={<FaqsScreen />} />
              <Route path="/blogs" element={<BlogsScreen />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />
              <Route path="/terms-and-condition" element={<TermsAndConditionScreen />} />
              <Route path="/projects" element={<ProjectScreen />} />
              {data.map((item, index) => {
                return (<Route key={index} path={item.url} element={<ServiceDetailPage data={item} />} />)
              })}
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </Provider>
  );
};

export default App;
