import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import Shape1 from "../assets/images/shape2.svg";
import Shape2 from "../assets/images/shape2.svg";
import Shape3 from "../assets/images/shape3.svg";
import Shape4 from "../assets/images/shape4.svg";
import ReactHelmet from "../components/ReactHelmet";
import OurTeams from "../components/our-teams";
import Product1 from "../assets/images/services-left-image/product-1.jpg";
import Product2 from "../assets/images/services-left-image/product-2.jpg";
import Product3 from "../assets/images/services-left-image/product-3.jpg";
import { FaCode } from "react-icons/fa6";
import { TbTools } from "react-icons/tb";
import { MdAnimation } from "react-icons/md";
import { GoDatabase } from "react-icons/go";
import { FiGlobe } from "react-icons/fi";
import { FaRegFile } from "react-icons/fa";
import { FaRegFolder } from "react-icons/fa";
import { MdMonitor } from "react-icons/md";
import { MdMailOutline } from "react-icons/md";
import { IoMdCloudOutline } from "react-icons/io";
import { MdIntegrationInstructions } from "react-icons/md";
import { FiLayout } from "react-icons/fi";
import { FiSmartphone } from "react-icons/fi";
import { SiMaterialdesignicons } from "react-icons/si";
import { LuPenTool } from "react-icons/lu";
import { BsCart2 } from "react-icons/bs";
import { LuInfo } from "react-icons/lu";
import { FaHandBackFist } from "react-icons/fa6";
import { FaChartSimple } from "react-icons/fa6";
import { FaPeopleLine } from "react-icons/fa6";
import { GrStakeholder } from "react-icons/gr";

import { FaReact } from "react-icons/fa";
import { FaNodeJs } from "react-icons/fa";
import { SiDjango } from "react-icons/si";
import { FaLaravel } from "react-icons/fa";
import { FaAngular } from "react-icons/fa";
import { FaVuejs } from "react-icons/fa";
import { RiFlutterFill } from "react-icons/ri";
import { SiDotnet } from "react-icons/si";
import { SiWeb3Dotjs } from "react-icons/si";
import { SiRubyonrails } from "react-icons/si";
import { BiLogoMongodb } from "react-icons/bi";
import { DiMysql } from "react-icons/di";
import { MdLoop } from "react-icons/md";

import Amazon from "../assets/images/partner-img/amazon.png";
import AmazonHover from "../assets/images/partner-img/amazon-hover.png";
import Slack from "../assets/images/partner-img/slack.png";
import SlackHover from "../assets/images/partner-img/slack-hover.png";
import Github from "../assets/images/partner-img/github.png";
import GithubHover from "../assets/images/partner-img/github-hover.png";
import Node from "../assets/images/partner-img/node.png";
import NodeHover from "../assets/images/partner-img/node-hover.png";
import Angular from "../assets/images/partner-img/angular.png";
import AngularHover from "../assets/images/partner-img/angular-hover.png";
import Stylus from "../assets/images/partner-img/stylus.png";
import StylusHover from "../assets/images/partner-img/stylus-hover.png";
import ReactIcon from "../assets/images/partner-img/react.png";
import ReactHover from "../assets/images/partner-img/react-hover.png";
import Nextjs from "../assets/images/partner-img/nextjs.png";
import NextjsHover from "../assets/images/partner-img/nextj-hover.png";
import Vuejs from "../assets/images/partner-img/vuejs.png";
import VuejsHover from "../assets/images/partner-img/vuejs-hover.png";
import * as animationData from '../assets/animations/lottie.json';
import Lottie from 'react-lottie';
import { useSelector, useDispatch } from "react-redux";
import * as commonAction from '../actions/common/CommonAction';

const HomeScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loadAnimation = useSelector((state) => state.CommonReducer.loadAnimation);
  const [videoComplete, setVideoComplete] = useState(loadAnimation);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const handleVideoEnd = () => {
    setVideoComplete(true);
    setTimeout(() => {
      dispatch(commonAction.hideAnimation());
    }, 2000)
  };

  const customSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    // autoplay: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1260,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const testinomialSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    dispatch(commonAction.hideLoader());
  }, [])

  return (
    <React.Fragment>
      <ReactHelmet
        MetaDescription="We make your ideas systematic"
        MetaKeywords="Software development, Full-stack development, Web development agency, Custom software solutions, Technology consulting, Software engineering services, Frontend development, Backend development, Mobile app development, UI/UX design, Agile development, DevOps services, Software architecture, Cloud solutions, Quality assurance (QA) testing, Blockchain Development, Web3 Development"
        canonicalLink="https://www.stackarray.io/"
        title="stackarray.io"
      />
      {!loadAnimation &&
        <div className={`video-container ${videoComplete ? 'hide-video' : ''}`}>
          <Lottie
            eventListeners={[
              {
                eventName: 'complete',
                callback: handleVideoEnd,
              },
            ]}
            speed={10}
            options={defaultOptions}
            height={'100vh'}
            width={'100vw'}
            isStopped={false}
            isPaused={false} />
        </div>
      }
      <div className="home-main-section">
        {/* Start Main Banner  */}
        <div className="main-banner">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="row h-100 justify-content-center align-items-center">
                  <div className="col-lg-5">
                    <div className="hero-content">
                      <h1>Catalyzing Tech Transformation</h1>
                      <p>
                        Your Partner in Software Development and Data Analytics From intuitive iOS and Android app development to robust enterprise solutions, we're here to empower your business to revolutionize processes and positively impact lives through innovative technology solutions.
                      </p>
                      <a
                        className="btn btn-primary"
                        onClick={() => navigate("/contact-us")}
                      >
                        Contact us
                      </a>
                    </div>
                  </div>

                  <div className="col-lg-6 offset-lg-1">
                    <div className="banner-image">
                      <img
                        src={require("../assets/images/banner-image/man.png")}
                        className="wow fadeInDown"
                        data-wow-delay="0.6s"
                        alt="man"
                      />
                      <img
                        src={require("../assets/images/banner-image/code.png")}
                        className="wow fadeInUp"
                        data-wow-delay="0.6s"
                        alt="code"
                      />
                      <img
                        src={require("../assets/images/banner-image/carpet.png")}
                        className="wow fadeInLeft"
                        data-wow-delay="0.6s"
                        alt="carpet"
                      />
                      <img
                        src={require("../assets/images/banner-image/bin.png")}
                        className="wow zoomIn"
                        data-wow-delay="0.6s"
                        alt="bin"
                      />
                      <img
                        src={require("../assets/images/banner-image/book.png")}
                        className="wow bounceIn"
                        data-wow-delay="0.6s"
                        alt="book"
                      />
                      <img
                        src={require("../assets/images/banner-image/dekstop.png")}
                        className="wow fadeInDown"
                        data-wow-delay="0.6s"
                        alt="dekstop"
                      />
                      <img
                        src={require("../assets/images/banner-image/dot.png")}
                        className="wow zoomIn"
                        data-wow-delay="0.6s"
                        alt="dot"
                      />
                      <img
                        src={require("../assets/images/banner-image/flower-top-big.png")}
                        className="wow fadeInUp"
                        data-wow-delay="0.6s"
                        alt="flower-top-big"
                      />
                      <img
                        src={require("../assets/images/banner-image/flower-top.png")}
                        className="wow rotateIn"
                        data-wow-delay="0.6s"
                        alt="flower-top"
                      />
                      <img
                        src={require("../assets/images/banner-image/keyboard.png")}
                        className="wow fadeInUp"
                        data-wow-delay="0.6s"
                        alt="keyboard"
                      />
                      <img
                        src={require("../assets/images/banner-image/pen.png")}
                        className="wow zoomIn"
                        data-wow-delay="0.6s"
                        alt="pen"
                      />
                      <img
                        src={require("../assets/images/banner-image/table.png")}
                        className="wow zoomIn"
                        data-wow-delay="0.6s"
                        alt="table"
                      />
                      <img
                        src={require("../assets/images/banner-image/tea-cup.png")}
                        className="wow fadeInLeft"
                        data-wow-delay="0.6s"
                        alt="tea-cup"
                      />
                      <img
                        src={require("../assets/images/banner-image/headphone.png")}
                        className="wow rollIn"
                        data-wow-delay="0.6s"
                        alt="headphone"
                      />
                      <img
                        src={require("../assets/images/banner-image/main-pic.png")}
                        className="wow fadeInUp"
                        data-wow-delay="0.6s"
                        alt="main-pic"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="shape1">
            <img src={require("../assets/images/shape1.png")} alt="shape" />
          </div>
          <div className="shape2 rotateme">
            <img src={Shape2} alt="shape" />
          </div>
          <div className="shape3">
            <img src={Shape3} alt="shape" />
          </div>
          <div className="shape4">
            <img src={Shape4} alt="shape" />
          </div>
          <div className="shape5">
            <img src={require("../assets/images/shape5.png")} alt="shape" />
          </div>
          <div className="shape6 rotateme">
            <img src={Shape2} alt="shape" />
          </div>
          <div className="shape7">
            <img src={Shape4} alt="shape" />
          </div>
          <div className="shape8 rotateme">
            <img src={Shape2} alt="shape" />
          </div>
        </div>
        {/* End Main Banner */}

        {/* Start Boxes Area */}
        <div className="boxes-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                <div className="single-box">
                  <div className="icon">
                    <FaHandBackFist size={24} />
                  </div>
                  <h3>Relentless <br /> Determination</h3>
                  <p>
                    We never give up. No matter the obstacles we face, we persist until we find the best solutions for everyone involved.
                  </p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                <div className="single-box bg-f78acb">
                  <div className="icon">
                    <FaChartSimple size={24} />
                  </div>
                  <h3>Continuous <br /> Evolution</h3>
                  <p>
                    We believe in constant growth. Every day, we strive to learn, improve, and adapt, making sure we're always moving forward.
                  </p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                <div className="single-box bg-c679e3">
                  <div className="icon">
                    <FaPeopleLine size={24} />
                  </div>
                  <h3>Unity in <br />Adversity</h3>
                  <p>
                    We're stronger together. When challenges arise, we support each other with respect and selflessness, facing adversity as a unified team.
                  </p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                <div className="single-box bg-eb6b3d">
                  <div className="icon">
                    <GrStakeholder size={24} />
                  </div>
                  <h3>Stakeholder <br /> Centric Solutions</h3>
                  <p>
                    Our stakeholders come first. We focus on understanding their needs and exceeding their expectations, building trust through valuable and impactful solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Boxes Area */}

        {/* Start Development Services */}
        <div className="services-area ptb-80 bg-f7fafd">
          <div className="container">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12 services-content">
                <div className="section-title">
                  <h2>Development Services</h2>
                  <div className="bar"></div>
                  <p>
                    Transforming ideas into robust solutions, Stackarray.io offers premium Development Services across diverse tech stacks. we cater to every technological need with expertise and innovation.
                  </p>
                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <Link className="box" to={'/development-services/react-and-react-native'}>
                      <FaReact size={26} /> React / React native
                    </Link>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <Link className="box" to={'/development-services/node-js'}>
                      <FaNodeJs size={26} /> Node.js
                    </Link>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <Link className="box" to={'/development-services/django'}>
                      <SiDjango size={18} /> Django
                    </Link>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <Link className="box" to={'/development-services/laravel'}>
                      <FaLaravel size={26} /> Laravel
                    </Link>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <Link className="box" to={'/development-services/angular'}>
                      <FaAngular size={26} /> Angular
                    </Link>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <Link className="box" to={'/development-services/vue-js'}>
                      <FaVuejs size={26} /> Vue.js
                    </Link>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <Link className="box" to={'/development-services/flutter'}>
                      <RiFlutterFill size={26} /> Flutter
                    </Link>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <Link className="box" to={'/development-services/web3-js'}>
                      <SiWeb3Dotjs size={26} /> Web3.js
                    </Link>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <Link className="box" to={'/development-services/dot-net-core'}>
                      <SiDotnet size={26} /> .NET Core
                    </Link>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <Link className="box" to={'/development-services/ruby-on-rails'}>
                      <SiRubyonrails size={26} /> Ruby on Rails
                    </Link>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <Link className="box" to={'/development-services/mongodb'}>
                      <BiLogoMongodb size={26} /> Mongodb
                    </Link>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <Link className="box" to={'/development-services/mysql-and-sql'}>
                      <DiMysql size={26} /> MySQL / SQL
                    </Link>
                  </div>

                </div>
              </div>

              <div className="col-lg-6 col-md-12 services-right-image">
                <img
                  src={require("../assets/images/iot-features-image/1.png")}
                  className="wow fadeInDown"
                  data-wow-delay="0.6s"
                  alt="book-self"
                />
                <img src="/static/media/cercle-shape.d9253f45e6ceb95e2f5c.png" className="bg-image rotateme" alt="shape" />
                <img
                  src={require("../assets/images/services-right-image/main-pic.png")}
                  className="wow fadeInUp"
                  data-wow-delay="0.6s"
                  alt="main-pic"
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Development Services */}

        {/* Start Designing Services */}
        <div className="services-area ptb-80">
          <div className="container">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12 services-left-image">
                <img
                  src={require("../assets/images/services-left-image/big-monitor.png")}
                  className="wow fadeInDown"
                  data-wow-delay="0.6s"
                  alt="big-monitor"
                />
                <img
                  src={require("../assets/images/services-left-image/creative.png")}
                  className="wow fadeInUp"
                  data-wow-delay="0.6s"
                  alt="creative"
                />
                <img
                  src={require("../assets/images/services-left-image/developer.png")}
                  className="wow fadeInLeft"
                  data-wow-delay="0.6s"
                  alt="developer"
                />
                <img
                  src={require("../assets/images/services-left-image/flower-top.png")}
                  className="wow zoomIn"
                  data-wow-delay="0.6s"
                  alt="flower-top"
                />
                <img
                  src={require("../assets/images/services-left-image/small-monitor.png")}
                  className="wow bounceIn"
                  data-wow-delay="0.6s"
                  alt="small-monitor"
                />
                <img
                  src={require("../assets/images/services-left-image/small-top.png")}
                  className="wow fadeInDown"
                  data-wow-delay="0.6s"
                  alt="small-top"
                />
                <img
                  src={require("../assets/images/services-left-image/table.png")}
                  className="wow zoomIn"
                  data-wow-delay="0.6s"
                  alt="table"
                />
                <img
                  src={require("../assets/images/services-left-image/target.png")}
                  className="wow fadeInUp"
                  data-wow-delay="0.6s"
                  alt="target"
                />
                <img
                  src={require("../assets/images/services-left-image/cercle-shape.png")}
                  className="bg-image rotateme"
                  alt="shape"
                />
                <img
                  src={require("../assets/images/services-left-image/main-pic.png")}
                  className="wow fadeInUp"
                  data-wow-delay="0.6s"
                  alt="main-pic"
                />
              </div>

              <div className="col-lg-6 col-md-12 services-content">
                <div className="section-title">
                  <h2>Designing Services</h2>
                  <div className="bar"></div>
                  <p>
                    Transforming visions into captivating digital experiences, Stackarray.io offers top-notch Designing Services tailored to your needs. Harnessing the power of Figma, Adobe XD, and a suite of Adobe products, we specialize in crafting stunning mobile app designs, websites, logos, animations, and more.
                  </p>
                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <Link className="box" to={'/designing-services/website-design'}>
                      <DiMysql size={26} />Websites design
                    </Link>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <Link className="box" to={'/designing-services/figma-adobe'}>
                      <TbTools size={26} /> Figma / Adobe XD
                    </Link>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <Link className="box" to={'/designing-services/mobile-app-design'}>
                      <FiSmartphone size={26} /> Mobile apps design
                    </Link>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <Link className="box" to={'/designing-services/logo-animations'}>
                      <MdAnimation size={26} /> Logo animations
                    </Link>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <Link className="box" to={'/designing-services/logo-design'}>
                      <SiMaterialdesignicons size={26} /> Logo Design
                    </Link>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <Link className="box" to={'/designing-services/ux-ui-design'}>
                      <LuPenTool size={24} /> UX/UI design
                    </Link>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <Link className="box" to={'/designing-services/e-commerce-web-design'}>
                      <BsCart2 size={24} /> E-commerce web design
                    </Link>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <Link className="box" to={'/designing-services/animations'}>
                      <MdAnimation size={24} /> Animations
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Design Services */}

        {/* Start Cloud Hosting Services */}
        <div className="services-area ptb-80 bg-f7fafd">
          <div className="container">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12 services-content">
                <div className="section-title">
                  <h2>Cloud Hosting Services</h2>
                  <div className="bar"></div>
                  <p>
                    Experience seamless cloud hosting solutions with Stackarray.io, your go-to multi-stack agency. Harness the power of AWS, GCP, and DigitalOcean for unparalleled performance and reliability.
                  </p>
                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="box">
                      <GoDatabase size={26} /> Managed services
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="box">
                      <IoMdCloudOutline size={26} /> Cloud Monitoring
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="box">
                      <IoMdCloudOutline size={26} /> Cloud Automation
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="box">
                      <IoMdCloudOutline size={26} /> Cloud Integration
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="box">
                      <IoMdCloudOutline size={26} />Cloud Infrastructure
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="box">
                      <IoMdCloudOutline size={26} /> Cloud Strategy
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="box">
                      <IoMdCloudOutline size={26} /> Cloud Migaration
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="box">
                      <IoMdCloudOutline size={26} /> Cloud Security
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12 services-right-image">
                <img
                  src={require("../assets/images/services-right-image/book-self.png")}
                  className="wow fadeInDown"
                  data-wow-delay="0.6s"
                  alt="book-self"
                />
                <img
                  src={require("../assets/images/services-right-image/box.png")}
                  className="wow fadeInUp"
                  data-wow-delay="0.6s"
                  alt="box"
                />
                <img
                  src={require("../assets/images/services-right-image/chair.png")}
                  className="wow fadeInLeft"
                  data-wow-delay="0.6s"
                  alt="chair"
                />
                <img
                  src={require("../assets/images/services-right-image/cloud.png")}
                  className="wow zoomIn"
                  data-wow-delay="0.6s"
                  alt="cloud"
                />
                <img
                  src={require("../assets/images/services-right-image/cup.png")}
                  className="wow bounceIn"
                  data-wow-delay="0.6s"
                  alt="cup"
                />
                <img
                  src={require("../assets/images/services-right-image/flower-top.png")}
                  className="wow fadeInDown"
                  data-wow-delay="0.6s"
                  alt="flower"
                />
                <img
                  src={require("../assets/images/services-right-image/head-phone.png")}
                  className="wow zoomIn"
                  data-wow-delay="0.6s"
                  alt="head-phone"
                />
                <img
                  src={require("../assets/images/services-right-image/monitor.png")}
                  className="wow fadeInUp"
                  data-wow-delay="0.6s"
                  alt="monitor"
                />
                <img
                  src={require("../assets/images/services-right-image/mug.png")}
                  className="wow rotateIn"
                  data-wow-delay="0.6s"
                  alt="mug"
                />
                <img
                  src={require("../assets/images/services-right-image/table.png")}
                  className="wow fadeInUp"
                  data-wow-delay="0.6s"
                  alt="table"
                />
                <img
                  src={require("../assets/images/services-right-image/tissue.png")}
                  className="wow zoomIn"
                  data-wow-delay="0.6s"
                  alt="tissue"
                />
                <img
                  src={require("../assets/images/services-right-image/water-bottle.png")}
                  className="wow zoomIn"
                  data-wow-delay="0.6s"
                  alt="water-bottle"
                />
                <img
                  src={require("../assets/images/services-right-image/wifi.png")}
                  className="wow fadeInLeft"
                  data-wow-delay="0.6s"
                  alt="wifi"
                />
                <img
                  src={require("../assets/images/services-right-image/cercle-shape.png")}
                  className="bg-image rotateme"
                  alt="shape"
                />
                <img
                  src={require("../assets/images/services-right-image/main-pic.png")}
                  className="wow fadeInUp"
                  data-wow-delay="0.6s"
                  alt="main-pic"
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Cloud Hosting Services */}

        {/* Start Features Area */}
        <div className="features-area ptb-80">
          <div className="container">
            <div className="section-title">
              <h2>Our Features</h2>
              <div className="bar"></div>
              <p>
                At Stackarray.io, we pride ourselves on delivering top-notch services across various stacks, including development, designing, and cloud services. Explore our features below to discover how we can elevate your projects.
              </p>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="single-features">
                  <div className="icon">
                    <MdLoop size={26} />
                  </div>
                  <h3>Agile Development</h3>
                  <p>
                    Streamlined workflows and rapid iterations ensure your project stays on track and delivers results efficiently.
                  </p>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="single-features">
                  <div className="icon bg-c679e3">
                    <LuPenTool size={26} />
                  </div>
                  <h3>Creative Designing</h3>
                  <p>
                    Innovative designs tailored to your brand, ensuring your digital presence stands out in today's competitive landscape.
                  </p>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="single-features">
                  <div className="icon bg-c679e3">
                    <IoMdCloudOutline size={26} />
                  </div>
                  <h3>Scalable Cloud Solutions</h3>
                  <p>
                    Reliable and scalable cloud infrastructure solutions that empower your applications to perform at their best, no matter the demand.
                  </p>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="single-features">
                  <div className="icon">
                    <MdIntegrationInstructions size={26} />
                  </div>
                  <h3>Seamless Integration</h3>
                  <p>
                    Integrate seamlessly with existing systems and technologies, enhancing interoperability and driving productivity
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
        {/* End Features Area */}

        {/* Start Team Area  */}
        <div className="team-area ptb-80 bg-f9f6f6 d-none">
          <div className="container">
            <div className="section-title">
              <h2>Our Awesome Team</h2>
              <div className="bar"></div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>

          <OurTeams settings={customSettings} />
        </div>
        {/* End Team Area */}

        {/* Start Fun Facts Area */}
        <div className="funfacts-area ptb-80">
          <div className="container">
            <div className="section-title">
              <h2>Our Journey: <br /> Understanding Our Users</h2>
              <div className="bar"></div>
              <p>
                At Stackarray.io, our journey is all about understanding our users. We're dedicated to listening to their needs, gathering feedback, and evolving our solutions to exceed their expectations. With thousands of satisfied clients, invaluable feedback, and a dedicated team, our journey is fueled by the relationships we build and the value we bring to everyone involved.
              </p>
            </div>

            <div className="row">
              <div className="col-lg-4 col-md-4 col-6 col-sm-4">
                <div className="funfact">
                  <h3>
                    <span className="odometer" data-count="180">
                      150+
                    </span>
                  </h3>
                  <p>In House Products</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-6 col-sm-4">
                <div className="funfact">
                  <h3>
                    <span className="odometer" data-count="20">
                      50+
                    </span>
                  </h3>
                  <p>Workers</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-6 col-sm-4">
                <div className="funfact">
                  <h3>
                    <span className="odometer" data-count="500">
                      100+
                    </span>
                  </h3>
                  <p>Clients</p>
                </div>
              </div>
            </div>

            <div className="contact-cta-box">
              <h3>Have any question about us?</h3>
              <p>Don't hesitate to contact us</p>
              <a
                className="btn btn-primary"
                onClick={() => navigate("/contact-us")}
              >
                Contact Us
              </a>
            </div>

            <div className="map-bg">
              <img src={require("../assets/images/map.png")} alt="map" />
            </div>
          </div>
        </div>
        {/* End Fun Facts Area */}

        {/* Start Works Area */}
        <div className="works-area ptb-80 bg-f7fafd d-none">
          <div className="container">
            <div className="section-title">
              <h2>Our Recent Works</h2>
              <div className="bar"></div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>

          <div className="container-fluid p-0 teams-slider">
            <Slider
              {...customSettings}
              className="works-slides ml-projects-slides owl-carousel owl-theme"
            >
              {/* <div className="single-works">
                  <img
                    src={require("../assets/images/works-image/1.jpg")}
                    alt="image"
                  />

                  <a href="#" className="icon">
                    <i data-feather="settings"></i>
                  </a>

                  <div className="works-content">
                    <h3>
                      <a href="#">Incredible infrastructure</a>
                    </h3>
                    <p>
                      Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor
                      incididunt ut labore dolore magna aliqua.
                    </p>
                  </div>
                </div>

                <div className="single-works">
                  <img
                    src={require("../assets/images/works-image/2.jpg")}
                    alt="image"
                  />

                  <a href="#" className="icon">
                    <i data-feather="settings"></i>
                  </a>

                  <div className="works-content">
                    <h3>
                      <a href="#">Incredible infrastructure</a>
                    </h3>
                    <p>
                      Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor
                      incididunt ut labore dolore magna aliqua.
                    </p>
                  </div>
                </div>

                <div className="single-works">
                  <img
                    src={require("../assets/images/works-image/3.jpg")}
                    alt="image"
                  />

                  <a href="#" className="icon">
                    <i data-feather="settings"></i>
                  </a>

                  <div className="works-content">
                    <h3>
                      <a href="#">Incredible infrastructure</a>
                    </h3>
                    <p>
                      Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor
                      incididunt ut labore dolore magna aliqua.
                    </p>
                  </div>
                </div>

                <div className="single-works">
                  <img
                    src={require("../assets/images/works-image/4.jpg")}
                    alt="image"
                  />

                  <a href="#" className="icon">
                    <i data-feather="settings"></i>
                  </a>

                  <div className="works-content">
                    <h3>
                      <a href="#">Incredible infrastructure</a>
                    </h3>
                    <p>
                      Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor
                      incididunt ut labore dolore magna aliqua.
                    </p>
                  </div>
                </div>

                <div className="single-works">
                  <img
                    src={require("../assets/images/works-image/5.jpg")}
                    alt="image"
                  />

                  <a href="#" className="icon">
                    <i data-feather="settings"></i>
                  </a>

                  <div className="works-content">
                    <h3>
                      <a href="#">Incredible infrastructure</a>
                    </h3>
                    <p>
                      Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor
                      incididunt ut labore dolore magna aliqua.
                    </p>
                  </div>
                </div> */}

              {/* <div className="ml-projects-slides owl-carousel owl-theme"> */}
              <div className="single-ml-projects-box">
                <img src={Product1} alt="image" />

                <div className="plus-icon">
                  <a>
                    <span></span>
                  </a>
                </div>
              </div>

              <div className="single-ml-projects-box">
                <img src={Product2} alt="image" />

                <div className="plus-icon">
                  <a>
                    <span></span>
                  </a>
                </div>
              </div>

              <div className="single-ml-projects-box">
                <img src={Product3} alt="image" />

                <div className="plus-icon">
                  <a>
                    <span></span>
                  </a>
                </div>
              </div>

              <div className="single-ml-projects-box">
                <img src={Product1} alt="image" />

                <div className="plus-icon">
                  <a>
                    <span></span>
                  </a>
                </div>
              </div>

              <div className="single-ml-projects-box">
                <img src={Product2} alt="image" />

                <div className="plus-icon">
                  <a>
                    <span></span>
                  </a>
                </div>
              </div>
              {/* </div> */}
            </Slider>
          </div>

          <div className="shape8 rotateme">
            <img src={Shape1} alt="shape" />
          </div>
          <div className="shape2 rotateme">
            <img src={Shape2} alt="shape" />
          </div>
          <div className="shape7">
            <img src={Shape4} alt="shape" />
          </div>
          <div className="shape4">
            <img src={Shape4} alt="shape" />
          </div>
        </div>
        {/* End Works Area */}

        {/* Start Feedback Area */}
        <div className="feedback-area ptb-80 bg-f7fafd">
          <div className="container">
            <div className="section-title">
              <h2>What users Saying</h2>
              <div className="bar"></div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>

            <div className="feedback-slides">
              <Slider {...testinomialSettings} className="client-feedback">
                <div className="item">
                  <div className="single-feedback">
                    <div className="client-img">
                      <img
                        src={require("../assets/images/client-image/1.jpg")}
                        alt="image"
                      />
                    </div>

                    <h3>John Lucy</h3>
                    <span>Web Developer</span>
                    <p>
                      Quis ipsum suspendisse ultrices gravida. Risus commodo
                      viverra maecenas accumsan lacus vel facilisis. Lorem ipsum
                      dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                  </div>
                </div>

                <div className="item">
                  <div className="single-feedback">
                    <div className="client-img">
                      <img
                        src={require("../assets/images/client-image/2.jpg")}
                        alt="image"
                      />
                    </div>

                    <h3>John Smith</h3>
                    <span>Web Developer</span>
                    <p>
                      Quis ipsum suspendisse ultrices gravida. Risus commodo
                      viverra maecenas accumsan lacus vel facilisis. Lorem ipsum
                      dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                  </div>
                </div>

                <div className="item">
                  <div className="single-feedback">
                    <div className="client-img">
                      <img
                        src={require("../assets/images/client-image/3.jpg")}
                        alt="image"
                      />
                    </div>

                    <h3>Maxwel Warner</h3>
                    <span>Web Developer</span>
                    <p>
                      Quis ipsum suspendisse ultrices gravida. Risus commodo
                      viverra maecenas accumsan lacus vel facilisis. Lorem ipsum
                      dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                  </div>
                </div>

                <div className="item">
                  <div className="single-feedback">
                    <div className="client-img">
                      <img
                        src={require("../assets/images/client-image/4.jpg")}
                        alt="image"
                      />
                    </div>

                    <h3>Ross Taylor</h3>
                    <span>Web Developer</span>
                    <p>
                      Quis ipsum suspendisse ultrices gravida. Risus commodo
                      viverra maecenas accumsan lacus vel facilisis. Lorem ipsum
                      dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                  </div>
                </div>

                <div className="item">
                  <div className="single-feedback">
                    <div className="client-img">
                      <img
                        src={require("../assets/images/client-image/5.jpg")}
                        alt="image"
                      />
                    </div>

                    <h3>James Anderson</h3>
                    <span>Web Developer</span>
                    <p>
                      Quis ipsum suspendisse ultrices gravida. Risus commodo
                      viverra maecenas accumsan lacus vel facilisis. Lorem ipsum
                      dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                  </div>
                </div>

                <div className="item">
                  <div className="single-feedback">
                    <div className="client-img">
                      <img
                        src={require("../assets/images/client-image/1.jpg")}
                        alt="image"
                      />
                    </div>

                    <h3>Steven Smith</h3>
                    <span>Web Developer</span>
                    <p>
                      Quis ipsum suspendisse ultrices gravida. Risus commodo
                      viverra maecenas accumsan lacus vel facilisis. Lorem ipsum
                      dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                  </div>
                </div>

                <div className="item">
                  <div className="single-feedback">
                    <div className="client-img">
                      <img
                        src={require("../assets/images/client-image/2.jpg")}
                        alt="image"
                      />
                    </div>

                    <h3>Steven Lucy</h3>
                    <span>Web Developer</span>
                    <p>
                      Quis ipsum suspendisse ultrices gravida. Risus commodo
                      viverra maecenas accumsan lacus vel facilisis. Lorem ipsum
                      dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                  </div>
                </div>

                <div className="item">
                  <div className="single-feedback">
                    <div className="client-img">
                      <img
                        src={require("../assets/images/client-image/3.jpg")}
                        alt="image"
                      />
                    </div>

                    <h3>John Terry</h3>
                    <span>Web Developer</span>
                    <p>
                      Quis ipsum suspendisse ultrices gravida. Risus commodo
                      viverra maecenas accumsan lacus vel facilisis. Lorem ipsum
                      dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                  </div>
                </div>
              </Slider>

              {/* <div className="client-thumbnails">
                              <div>
                                  <div className="item">
                                      <div className="img-fill"><img src={require("../assets/images/client-image/1.jpg")} alt="client" /></div>
                                  </div>

                                  <div className="item">
                                      <div className="img-fill"><img src={require("../assets/images/client-image/2.jpg")} alt="client" /></div>
                                  </div>

                                  <div className="item">
                                      <div className="img-fill"><img src={require("../assets/images/client-image/3.jpg")} alt="client" /></div>
                                  </div>

                                  <div className="item">
                                      <div className="img-fill"><img src={require("../assets/images/client-image/4.jpg")} alt="client" /></div>
                                  </div>

                                  <div className="item">
                                      <div className="img-fill"><img src={require("../assets/images/client-image/5.jpg")} alt="client" /></div>
                                  </div>

                                  <div className="item">
                                      <div className="img-fill"><img src={require("../assets/images/client-image/1.jpg")} alt="client" /></div>
                                  </div>

                                  <div className="item">
                                      <div className="img-fill"><img src={require("../assets/images/client-image/2.jpg")} alt="client" /></div>
                                  </div>

                                  <div className="item">
                                      <div className="img-fill"><img src={require("../assets/images/client-image/3.jpg")} alt="client" /></div>
                                  </div>
                              </div>

                              <button className="prev-arrow slick-arrow">
                                  <i data-feather="arrow-left"></i>
                              </button>

                              <button className="next-arrow slick-arrow">
                                  <i data-feather="arrow-right"></i>
                              </button>
                          </div> */}
            </div>
          </div>

          <div className="shape1">
            <img src={require("../assets/images/shape1.png")} alt="shape" />
          </div>
          <div className="shape2 rotateme">
            <img src={Shape2} alt="shape" />
          </div>
          <div className="shape4">
            <img src={Shape4} alt="shape" />
          </div>
          <div className="shape5">
            <img src={require("../assets/images/shape5.png")} alt="shape" />
          </div>
          <div className="shape6 rotateme">
            <img src={Shape4} alt="shape" />
          </div>
          <div className="shape7">
            <img src={Shape4} alt="shape" />
          </div>
          <div className="shape8 rotateme">
            <img src={Shape2} alt="shape" />
          </div>
        </div>
        {/* End Feedback Area */}

        {/* Start Ready To Talk Area */}
        <div className="ready-to-talk">
          <div className="container">
            <h3>Ready to talk?</h3>
            <p>Our team is here to answer your question about Stackarray.io</p>
            <a href="contact.html" className="btn btn-primary">
              Contact Us
            </a>
          </div>
        </div>

        <div className="partner-area partner-section">
          <div className="container">
            <div className="partner-inner">
              <div className="row">
                <div className="col-lg-2 col-md-3 col-6 col-sm-4">
                  <a href="#">
                    <img src={ReactIcon} alt="partner" width={50} />
                    <img src={ReactHover} alt="partner" width={50} />
                  </a>
                </div>
                <div className="col-lg-2 col-md-3 col-6 col-sm-4">
                  <a href="#">
                    <img src={Nextjs} alt="partner" width={90} />
                    <img src={NextjsHover} alt="partner" width={90} />
                  </a>
                </div>
                <div className="col-lg-2 col-md-3 col-6 col-sm-4">
                  <a href="#">
                    <img src={Vuejs} alt="partner" width={50} />
                    <img src={VuejsHover} alt="partner" width={50} />
                  </a>
                </div>
                <div className="col-lg-2 col-md-3 col-6 col-sm-4">
                  <a href="#">
                    <img src={Angular} alt="partner" />
                    <img src={AngularHover} alt="partner" />
                  </a>
                </div>
                <div className="col-lg-2 col-md-3 col-6 col-sm-4">
                  <a href="#">
                    <img src={Node} alt="partner" />
                    <img src={NodeHover} alt="partner" />
                  </a>
                </div>
                <div className="col-lg-2 col-md-3 col-6 col-sm-4">
                  <a href="#">
                    <img src={Stylus} alt="partner" />
                    <img src={StylusHover} alt="partner" />
                  </a>
                </div>
                <div className="col-lg-2 col-md-3 col-6 col-sm-4">
                  <a href="#">
                    <img src={Slack} alt="partner" />
                    <img src={SlackHover} alt="partner" />
                  </a>
                </div>
                <div className="col-lg-2 col-md-3 col-6 col-sm-4">
                  <a href="#">
                    <img src={Github} alt="partner" />
                    <img src={GithubHover} alt="partner" />
                  </a>
                </div>
                <div className="col-lg-2 col-md-3 col-6 col-sm-4">
                  <a href="#">
                    <img src={Amazon} alt="partner" />
                    <img src={AmazonHover} alt="partner" />
                  </a>
                </div>
                <div className="col-lg-2 col-md-3 col-6 col-sm-4">
                  <a href="#">
                    <img src={Node} alt="partner" />
                    <img src={NodeHover} alt="partner" />
                  </a>
                </div>
                <div className="col-lg-2 col-md-3 col-6 col-sm-4">
                  <a href="#">
                    <img src={Stylus} alt="partner" />
                    <img src={StylusHover} alt="partner" />
                  </a>
                </div>
                <div className="col-lg-2 col-md-3 col-6 col-sm-4">
                  <a href="#">
                    <img src={Slack} alt="partner" />
                    <img src={SlackHover} alt="partner" />
                  </a>
                </div>
                <div className="col-lg-2 col-md-3 col-6 col-sm-4">
                  <a href="#">
                    <img src={Github} alt="partner" />
                    <img src={GithubHover} alt="partner" />
                  </a>
                </div>
                <div className="col-lg-2 col-md-3 col-6 col-sm-4">
                  <a href="#">
                    <img src={Angular} alt="partner" />
                    <img src={AngularHover} alt="partner" />
                  </a>
                </div>
                <div className="col-lg-2 col-md-3 col-6 col-sm-4">
                  <a href="#">
                    <img src={Amazon} alt="partner" />
                    <img src={AmazonHover} alt="partner" />
                  </a>
                </div>
                <div className="col-lg-2 col-md-3 col-6 col-sm-4">
                  <a href="#">
                    <img src={Node} alt="partner" />
                    <img src={NodeHover} alt="partner" />
                  </a>
                </div>
                <div className="col-lg-2 col-md-3 col-6 col-sm-4">
                  <a href="#">
                    <img src={Stylus} alt="partner" />
                    <img src={StylusHover} alt="partner" />
                  </a>
                </div>
                <div className="col-lg-2 col-md-3 col-6 col-sm-4">
                  <a href="#">
                    <img src={Slack} alt="partner" />
                    <img src={SlackHover} alt="partner" />
                  </a>
                </div>
                <div className="col-lg-2 col-md-3 col-6 col-sm-4">
                  <a href="#">
                    <img src={Github} alt="partner" />
                    <img src={GithubHover} alt="partner" />
                  </a>
                </div>
                <div className="col-lg-2 col-md-3 col-6 col-sm-4">
                  <a href="#">
                    <img src={Angular} alt="partner" />
                    <img src={AngularHover} alt="partner" />
                  </a>
                </div>
                <div className="col-lg-2 col-md-3 col-6 col-sm-4">
                  <a href="#">
                    <img src={Amazon} alt="partner" />
                    <img src={AmazonHover} alt="partner" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Ready To Talk Area */}
      </div>
    </React.Fragment>
  );
};

export default HomeScreen;
