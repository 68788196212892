import * as ActionTypes from '../ActionTypes';

export const showAnimation = (text) => {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.SHOW_ANIMATION
        });
    }
};

export const hideAnimation = (text) => {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.HIDE_ANIMATION
        });
    }
};

export const hideLoader = (text) => {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.HIDE_LOADER
        });
    }
};