import { Link } from "react-router-dom";
import Shape1 from "../assets/images/shape2.svg";
import Shape2 from "../assets/images/shape2.svg";
import Map from "../assets/images/map.png";
import Logo from "../assets/images/logo.png";
import WhiteLogo from "../assets/images/white-logo.png";
import { SlSocialFacebook } from "react-icons/sl";
import { RiTwitterXFill } from "react-icons/ri";
import { FaInstagram } from "react-icons/fa";
import { SlSocialLinkedin } from "react-icons/sl";
import { IoLocationOutline } from "react-icons/io5";
import { TfiEmail } from "react-icons/tfi";
import { FiPhoneCall } from "react-icons/fi";

const Footer = () => {
  return (
    <footer className="footer-area bg-f7fafd">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="single-footer-widget">
              <div className="logo black-logo">
                <Link to={"/"}>
                  <img src={Logo} alt="logo" width={180} />
                </Link>
              </div>
              {/* <div className="logo white-logo">
                <Link to={"/"}>
                    <img src={WhiteLogo} alt="logo" />
                </Link>
              </div> */}
              <p className="desc">
                Your Partner in Software Development and Data Analytics From intuitive iOS and Android app development to robust enterprise solutions, we're here to empower your business to revolutionize processes and positively impact lives through innovative technology solutions.
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="single-footer-widget pl-5">
              <h3>Company</h3>
              <ul className="list">
                <li>
                  <Link to={"/"}>Home</Link>
                </li>
                <li>
                  {/* <a href="services-1.html">Services</a> */}
                  <Link to={"/about-us"}>About Us</Link>
                </li>
                <li>
                  <Link to={"/services"}>Services</Link>
                </li>
                {/* <li>
                  <Link to={"/projects"}>Projects</Link>
                </li> */}
                {/* <li>
                  <Link to={"/blogs"}>Blogs</Link>
                </li> */}
                {/* <li>
                  <a href="blog-1.html">Latest News</a>
                </li> */}
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="single-footer-widget">
              <h3>Support</h3>
              <ul className="list">
                <li>
                  <Link to={"/faqs"}>FAQ's</Link>
                </li>
                <li>
                  {/* <a href="privacy-policy.html">Privacy Policy</a> */}
                  <Link to={"/privacy-policy"}>Privacy Policy</Link>
                </li>
                <li>
                  {/* <a href="terms-conditions.html">Terms & Condition</a> */}
                  <Link to={"/terms-and-condition"}>Terms & Condition</Link>
                </li>
                {/* <li>
                  <a href="contact.html">Support Us</a>
                </li> */}
                <li>
                  < Link to={"/contact-us"}>Contact us</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="single-footer-widget">
              <h3>Address</h3>

              <ul className="footer-contact-info">
                <li>
                  <IoLocationOutline size={22} />
                  5900 Balcones Dr #14880, Austin, TX 78731
                </li>
                <li>
                  <TfiEmail size={22} />
                  <a href="mailto:support@stackarray.io">
                    support@stackarray.io
                  </a>
                </li>
                <li>
                  <FiPhoneCall size={22} />
                  <a href="tel:+17373075660">+17373075660</a>
                </li>
              </ul>
              <ul className="social-links">
                <li>
                  <a className="facebook" href="https://www.facebook.com/profile.php?id=61556746838286" target="_blank">
                    <SlSocialFacebook />
                  </a>
                </li>
                {/* <li>
                  <a className="twitter" target="_blank">
                    <RiTwitterXFill />
                  </a>
                </li> */}
                <li>
                  <a className="instagram" href="https://www.instagram.com/stackarray.io/?hl=en" target="_blank">
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  <a className="linkedin" href="https://www.linkedin.com/company/stackarray" target="_blank">
                    <SlSocialLinkedin />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="copyright-area">
              <p>Copyright Stack Array. All Rights Reserved</p>
            </div>
          </div>
        </div>
      </div>

      <img src={Map} className="map" alt="map" />
      <div className="shape1">
        <img src={Shape1} alt="shape" />
      </div>
      <div className="shape8 rotateme">
        <img src={Shape2} alt="shape" />
      </div>
    </footer>
  );
};

export default Footer;
