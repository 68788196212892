import { Helmet } from "react-helmet";

const ReactHelmet = ({
  title = "",
  canonicalLink = "",
  MetaKeywords = "",
  MetaDescription = "",
}) => {
  return (
    <Helmet defer={false}>
      <meta charSet="utf-8" />
      <meta
        data-react-helmet="true"
        name="description"
        content={MetaDescription}
      />
      <meta data-react-helmet="true" name="keywords" content={MetaKeywords} />
      <title>{title}</title>
      {canonicalLink && <link rel="canonical" href={canonicalLink} />}
    </Helmet>
  );
};

export default ReactHelmet;
