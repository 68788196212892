import * as ActionTypes from '../../actions/ActionTypes';

const initialState = {
    loadAnimation: false,
    loader: true
}

const CommonReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SHOW_ANIMATION:
            return {
                ...state,
                loadAnimation: false
            };
        case ActionTypes.HIDE_ANIMATION:
            return {
                ...state,
                loadAnimation: true
            };
        case ActionTypes.HIDE_LOADER:
            return {
                ...state,
                loader: false
            };
        default:
            return state;
    }
};

export default CommonReducer;