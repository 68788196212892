import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import { NavLink } from "react-router-dom";

const Header = () => {
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const header = document.getElementById("header");
      if (window.scrollY > 120) {
        header.classList.add("is-sticky");
      } else {
        header.classList.remove("is-sticky");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header id="header" className="headroom">
      <div className="startp-responsive-nav">
        <div className="container">
          <div className="startp-responsive-menu">
            <div className="logo black-logo company-logo">
              <Link to={"/"}>
                <img src={Logo} alt="logo" />
              </Link>
            </div>
            {/* <div className="logo white-logo">
              <Link to={"/"}>
                <img src={WhiteLogo} alt="logo" />
              </Link>
            </div> */}
          </div>
        </div>
      </div>

      <div className="startp-nav">
        <div className="container">
          <nav className="navbar navbar-expand-md navbar-light">
            <Link className="navbar-brand black-logo" to={"/"}>
              <img
                style={{ width: 180 }}
                src={require("../assets/images/logo.png")}
                alt="logo"
              />
            </Link>
            {/* <Link className="navbar-brand white-logo" to={"/"}>
              <img
                src={require("../assets/images/white-logo.png")}
                alt="logo"
              />
            </Link> */}

            <div
              className="collapse navbar-collapse mean-menu"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav nav ml-auto">
                <li className="nav-item">
                  <Link
                    to="/"
                    className={
                      location.pathname === "/" ? "nav-link active" : "nav-link"
                    }
                  >
                    Home
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/about-us"
                    className={
                      location.pathname === "/about-us"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    About
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/services"
                    className={
                      location.pathname === "/services"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    Our Services
                  </Link>
                </li>

                {/* <li className="nav-item">
                  <Link
                    to="/projects"
                    className={
                      location.pathname === "/projects"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    Projects
                  </Link>
                </li> */}

                {/* <li className="nav-item">
                  <Link
                    to="/blogs"
                    className={
                      location.pathname === "/blogs"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    Blogs
                  </Link>
                </li> */}

                <li className="nav-item">
                  <Link
                    to="/contact-us"
                    className={
                      location.pathname === "/contact-us"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>

      <div className="others-option-for-responsive">
        <div className="container">
          <div className="dot-menu">
            <div className="inner">
              <div className="circle circle-one"></div>
              <div className="circle circle-two"></div>
              <div className="circle circle-three"></div>
            </div>
          </div>

          <div className="container">
            <div className="option-inner">
              <div className="others-option">
                <a href="cart.html" className="cart-wrapper-btn">
                  <i data-feather="shopping-cart"></i>
                  <span>0</span>
                </a>
                <a href="contact.html" className="btn btn-light">
                  Support
                </a>
                <a href="login.html" className="btn btn-primary">
                  Login
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
